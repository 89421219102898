import React from 'react';
import moment from 'moment';
import { Line } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import { useUser } from './user';
import Tracking from './tracking';

function Dashboard() {
  Tracking();
  const { user, plan, apiKey, getUsage, subscribe } = useUser();
  const [isLoading, setLoading] = React.useState(false);
  const [usage, setUsage] = React.useState('');
  const [chartData, setChartData] = React.useState({
          labels: [],
          datasets: [
            {
              label: "Usage",
              data: [],
              fill: true,
              backgroundColor: "rgba(75,192,192,0.2)",
              borderColor: "rgba(75,192,192,1)"
            }
          ]
        });
  const history = useHistory();

  React.useEffect(() => {
    getData();
  },[user,plan]);

  function getData() {
    if (!isLoading) {
      setLoading(true);
      getUsage()
        .then(data => {
          console.log(data);
          var labels = [];
          var dataset = [];
          var date = moment(data.startDate);
          if (data && data.items && Object.keys(data.items).length > 0) {
            var dataItems = data.items[Object.keys(data.items)[0]];
            dataItems.forEach(item => {
              dataset.push(item[0]);
              labels.push(date.format('MMM D'));
              date.add(1,'d');
            });
            var lastItem = dataItems[dataItems.length - 1];
            if (data.plan === 'BASIC') {
              setUsage(`${lastItem[0]} out of 100 daily calls`);
            } else if (data.plan === 'PRO') {
              setUsage(`${data.quota.limit - lastItem[1]} out of ${data.quota.limit} monthly calls`);
            } else if (data.plan === 'ULTRA') {
              setUsage(`${data.quota.limit - lastItem[1]} out of ${data.quota.limit} monthly calls`);
            } else if (data.plan === 'MEGA') {
              setUsage('unlimited');
            }
          }
          setChartData({
                  labels: labels,
                  datasets: [
                    {
                      label: "Usage",
                      data: dataset,
                      fill: true,
                      backgroundColor: "rgba(75,192,192,0.2)",
                      borderColor: "rgba(75,192,192,1)"
                    }
                  ]
                });
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
        });
      }
  }

  function onUpdatePaymentClick(e) {
    history.push("/payment");
  }

  function onChangePlanClick(e) {
    history.push("/pricing");
  }

  function onUnsubscribeClick(e) {
    subscribe('BASIC')
      .then(() => {
        getData();
      });
  }

  return (
    <div className="DashboardContainer">
      <div className="DashboardElements">
        <div>
          <b>API key</b>: {apiKey}
        </div>
        <div>
          <b>Subscription plan</b>: {plan}
        </div>
        <div>
          <b>Usage</b>: {usage}
        </div>
        <div className="DashboardButtons">
          <button className="btn btn-primary btn-sm" onClick={onChangePlanClick}>Change plan</button>
          <button className={plan==='BASIC'?'btn btn-primary btn-sm AppHiddenElement':'btn btn-primary btn-sm'} onClick={onUpdatePaymentClick}>Update payment</button>
          <button className={plan==='BASIC'?'btn btn-primary btn-sm AppHiddenElement':'btn btn-primary btn-sm'} onClick={onUnsubscribeClick}>Unsubscribe</button>
        </div>
      </div>
      <div className="DashboardChart">
        <Line data={chartData} />
      </div>
    </div>
  );
}

export default Dashboard;