import Tracking from './tracking';

function Privacy() {
  Tracking();
  return (
    <article className="PrivacyArticle">
        <header>
            <h1>Information Collected and Used</h1>
        </header>
        <section>
            <p>Here you will find information describing the data collected by our website (the “Website”) and via our API (the “API”), hereinafter called the “Service”.</p>
            <p>This Privacy Statement governs personal information we collects from customers, users and online visitors (“you” or “your”) in connection with your use of the Service, applications and services (including support and education), where we post or link to this Privacy Statement, as well as information we automatically collect from your online visits (e.g. data collected via cookies). For the purposes of this Privacy Statement, “personal information” means any information that, by itself, can identify you or can be combined with other information to identify you.</p>
            <p>As a general code of conduct, we always try our best to minimize the amount of information we need to collect and use, while still providing you with the highest level of Service.</p>
        </section>
        <section>
            <h2>Email</h2>
            <p>We may collect your email for purposes of authorizations and identifications on the Website as well as for identification purposes in Stripe service. The email is also may be used for sending email notifications and marketing campaigns.</p>
        </section>
        <section>
            <h2>Log data</h2>
            <p>Each access to our Website generates logs data that includes, but may not be limited to: your IP address, your web browser user-agent, the language preferences of your web browser, the date and time of your visits.</p>
            <p>We may use your personal information for legitimate business purposes, including: to provide and deliver the Services you request; send you transaction information; including confirmations and transaction status; product and services information, updates, security alerts and support and administrative messages; administer your account, including verifying your information; respond to your comments and questions and provide customer support or other services; offer Live Chat assistance to facilitate; operate and improve our websites, products and services; provide you with information about products and services; perform other functions or serve other purposes, as disclosed to you at the point of collection, or as otherwise required or allowed under applicable law.</p>
        </section>
        <section>
            <h2>Billing Data</h2>
            <p>We collect your credit card information when you subscribe and pay to access some part of the Service.</p>
            <p>We never store your credit card information. All payment and billing operations and services are processed by Stripe. Stripe allows us to see when invoices are due, processing or paid, or any other status.</p>
        </section>
        <section>
            <h2>Cookies</h2>
            <p>We use Google Analytics on our Website. Our website to collect statistics on your visits and interactions on our Website, which includes cookies required for our affiliate program.</p>
        </section>
        <section>
            <h2>Service Providers</h2>
            <p>We may employ third-party companies and individuals due to the following reasons: to facilitate our Service; to provide the Service on our behalf; to perform Service-related services; or to assist us in analyzing how our Service is used.  An example of a third-party service provider we use is Stripe for payment and billing and SendGrid for email marketing.</p>
        </section>
        <section>
            <h2>Security</h2>
            <p>The protection of your data is important to us. We are striving to use, to the best of our knowledge, available means of protection. However, please be aware that no method of transmission over the internet, or method of electronic storage is 100% secure or reliable, and we cannot guarantee the absolute security of the Service.</p>
        </section>
        <section>
            <h2>Data Sharing</h2>
            <p>We will share the data we collected about you for any legal obligations and rights. We may disclose your personal information in these circumstances: In connection with the establishment, exercise or defense of legal claims; to comply with laws or to respond to lawful requests or legal process; for fraud or security monitoring purposes (e.g., to detect and prevent cyberattacks); to protect our rights or our employees rights; or as otherwise permitted by applicable law.</p>
        </section>
        <section>
            <h2>Retention of Data</h2>
            <p>We may retain your personal information data as long as is necessary to fulfill the purposes for which it was collected and in accordance with applicable law.</p>
        </section>
        <section>
            <h2>Children’s Privacy</h2>
            <p>The Service does not address anyone under the age of 18. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us at support@financeapi.net so that we will be able to do necessary actions.</p>
        </section>
        <section>
            <h2>Changes to this Privacy Statement</h2>
            <p>We may update our Privacy statement from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Statement on this page. These changes are effective immediately after they are posted on this page. If we make any changes to this Privacy Statement, we will change the “Last Updated” date at the beginning of this Privacy Statement.</p>
        </section>
        <section>
            <h2>Contact</h2>
            <p>If you have any questions, concerns or comments about this Privacy Statement or our privacy practices, please contact us via email at support@financeapi.net</p>
        </section>
    </article>
  );
}

export default Privacy;