import React from 'react';
import { useHistory } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.min.css';
import { useUser } from './user';
import Tracking from './tracking';

function Pricing(props) {
  Tracking();
  const { user, plan, getSubscription, subscribe } = useUser();
  React.useEffect(() => {
    getSubscription();
  },[]);
  return (
    <div className="PricingTableContainer">
      <div className="PricingTable">
        <table>
          <tbody>
            <tr className="HeaderRow">
              <th className="FirstColoumnCell">
                <span className="bold">&nbsp;</span>
              </th>
              <th >
                <div className="PlanFlag">&nbsp;</div>
                <div className="PlanHeader">
                  <h2 className="PlanName">Basic</h2>
                  <h2 className="bold">US$0.00</h2>
                  <span>
                    <Plan plan="BASIC"/>
                  </span>
                </div>
              </th>
              <th>
                <div className="PlanFlag">&nbsp;</div>
                <div className="PlanHeader">
                  <h2 className="PlanName">Pro</h2>
                  <h2 className="bold">US$9.99</h2>
                  <span>
                    <Plan plan="PRO"/>
                  </span>
                </div>
              </th>
              <th>
                <div className="PlanFlag">
                  <span className="bold">Recommended</span>
                </div>
                <div className="PlanHeader">
                  <h2 className="PlanName">Ultra</h2>
                  <h2 className="bold">US$25.99</h2>
                  <span>
                    <Plan plan="ULTRA"/>
                  </span>
                </div>
              </th>
              <th>
                <div className="PlanFlag">&nbsp;</div>
                <div className="PlanHeader">
                  <h2 className="PlanName">Mega</h2>
                  <h2 className="bold">US$259.00</h2>
                  <span>
                    <Plan plan="MEGA"/>
                  </span>
                </div>
              </th>
              </tr>
              <tr className="LimitRow">
                <td className="FirstColoumnCell">
                  <span>Requests</span>
                </td>
                <td className="PlanDetailCell">
                  <div>
                    <div className="LimitQuotaRow bold">100 / day</div>
                    <div className="LimitOverageRow caption">Hard Limit</div>
                  </div>
                </td>
                <td className="PlanDetailCell">
                  <div>
                    <div className="LimitQuotaRow bold">20,000 / month</div>
                    <div className="LimitOverageRow caption">Hard Limit</div>
                  </div>
                </td>
                <td className="PlanDetailCell">
                  <div>
                    <div className="LimitQuotaRow bold">75,000 / month</div>
                    <div className="LimitOverageRow caption">Hard Limit</div>
                  </div>
                </td>
                <td className="PlanDetailCell">
                  <div>
                    <span>Unlimited</span>
                  </div>
                </td>
              </tr>
              <tr >
                <td className="FirstColoumnCell">
                  <span>Rate Limit</span>
                </td>
                <td className="PlanDetailCell">300 requests per minute</td>
                <td className="PlanDetailCell">300 requests per minute</td>
                <td className="PlanDetailCell">300 requests per minute</td>
                <td  className="PlanDetailCell">300 requests per minute</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  );
  
  function Plan(localProps) {
    const history = useHistory();
    function onPlanChange(plan) {
      if (user) {
        subscribe(plan)
          .then(data => {
            history.push({pathname:'/dashboard'});
          })
          .catch(err => {
            if (err.message === 'Request failed with status code 402') {
              history.push({pathname:'/payment',plan:plan});
            }
          });
      } else {
        history.push({pathname:'/payment',plan:plan});
      }
    }
    return (user && plan && localProps.plan === plan) ? (
      <div className="SubscribedIndicator">
        <span className="caption">Currently Subscribed</span>
        <a href="/dashboard" className="caption">Manage And View Usage</a>
      </div>
    ) : (user && plan) ? (
      <button type="button" className="btn btn-primary btn-sm" onClick={()=> onPlanChange(localProps.plan)}>Change Plan</button>
    ) : (
      <button type="button" className="btn btn-primary btn-sm" onClick={()=> onPlanChange(localProps.plan)}>Subscribe</button>
    );
  }
}

export default Pricing;