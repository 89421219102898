import React from 'react';
import {Elements,CardElement,useStripe,useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {
  useHistory,
  useLocation
} from "react-router-dom";
import { useUser } from './user';
import StripeLogo from './stripe-logo.svg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tracking from './tracking';


function Payment() {
  Tracking();
  const stripePromise = loadStripe('pk_live_51IvdOCG4YdRQ94KiFjSSiFCtzfy7FKwT6MckjHw5Xx0CK0iaBJzmWy5hKtbVKkGcsBz5ek57Ix3YaJ43k4tABgeP00eeFEf3bD');
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm />
    </Elements>
  );
}

function PaymentForm() {
  const { subscribe } = useUser();
  const [isPaymentLoading, setPaymentLoading] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  async function onPayment(e) {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    var body = {};
    if (location.plan) {
      body.plan = location.plan;
    }
    const cardElement = elements.getElement(CardElement);
    var payload = await stripe.createToken(cardElement);
    if (!payload.token) {
      setPaymentLoading(false);
      var msg = 'Please enter correct credit card information';
      if (payload.error && payload.error.message) {
        msg = payload.error.message;
      }
      toast.error(msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
      return;
    } else 
    subscribe(location.plan,payload.token.id)
      .then(data => {
        setPaymentLoading(false);
        if (location.plan) {
          history.push('/pricing');
        } else {
          history.push('/dashboard');
        }
      })
      .catch(err => {
        setPaymentLoading(false);
        toast.error('Something went wrong. Please email to support@financeapi.net', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      });
  }
  var options = {
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          };
  return (
    <div className="PaymentContainer">
      <h3>Billing Information</h3>
      <form className="PaymentStripeForm" onSubmit={onPayment}>
        <CardElement options={options} className="StripeElement" />
        <button type="submit" className="btn btn-primary btn-sm PaymentPayButton" disabled={isPaymentLoading}>
          {isPaymentLoading ? "Loading..." : location.plan?'Subscribe':'Update'}
        </button>
      </form>
      <img src={StripeLogo} alt="Stripe"/>
    </div>
  );
}

export default Payment;