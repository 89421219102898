import React from 'react';
import { useHistory } from "react-router-dom";
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import { useUser } from './user';
import Tracking from './tracking';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function Specification(props) {
  Tracking();
  const { getSubscription } = useUser();
  const history = useHistory();
  function onSwaggerComplete(swaggerUi) {
    getSubscription()
      .then(data => {
        if (data  && data.apikey) {
          swaggerUi.preauthorizeApiKey('ApiKeyAuth', data.apikey);
        }
      });
  }
  const UpdateAuthorizePlugin = () => {
    return {
      wrapComponents: {
        authorizeBtn: () => (props) => {
          return (
            <div className="auth-wrapper">
              <button className={props.isAuthorized ? "btn authorize locked" : "btn authorize unlocked"} onClick={() =>{ history.push("/pricing"); }}>
                <span>Authorize</span>
                <svg width="20" height="20">
                  <use href={ props.isAuthorized ? "#locked" : "#unlocked" } xlinkHref={ props.isAuthorized ? "#locked" : "#unlocked" } />
                </svg>
              </button>
            </div>
          );
        }
      }
    }
  }

  return (
    <div className='Specification'>
      <Tabs defaultIndex={parseInt(props.tab)}>
        <TabList>
          <Tab><b>YH Finance</b></Tab>
          <Tab><b>SA Finance</b></Tab>
        </TabList>

        <TabPanel>
          <div>
            <SwaggerUI url="/yh-finance-api-specification.json" onComplete={(swaggerUi) => onSwaggerComplete(swaggerUi)} plugins={[UpdateAuthorizePlugin]}/>
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <SwaggerUI url="/sa-finance-api-specification.json" onComplete={(swaggerUi) => onSwaggerComplete(swaggerUi)} plugins={[UpdateAuthorizePlugin]}/>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default Specification;