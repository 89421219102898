import React from 'react';
import { useLocation } from 'react-router-dom';

function Tracking() {
  const trackingId = 'AW-11308497264';
  var location = useLocation();

  React.useEffect(() => {
    if (!window.gtag) return
    if (!trackingId) {
      console.log(
        'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
      )
      return
    }
    window.gtag('config', trackingId, { page_path: location.pathname });
  }, [location]);
}

export default Tracking;