import React from 'react';
import { Auth, API } from 'aws-amplify';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const UserContext = React.createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [plan, setPlan] = React.useState(null);
  const [apiKey, setApiKey] = React.useState(null);

  React.useEffect(() => {
//    Auth.configure({
//      // ...
//    });
    Auth.currentAuthenticatedUser()
      .then(user => {
        setUser(user);
      })
      .catch(err => {
        setUser(null);
      });
  }, []);

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setUser(authData);
    });
  }, []);

  const logout = () =>
    Auth.signOut().then(data => {
      setUser(null);
      setPlan(null);
      setApiKey(null);
      return data;
    });

  const getSubscription = () =>
    Auth.currentAuthenticatedUser()
      .then(user => {
        const token = user.signInUserSession.idToken.jwtToken;
        return API.get('api230deb2c','/subscription', {headers: { Authorization: token }});
      })
      .then(data => {
        setPlan(data.plan);
        setApiKey(data.apikey);
        return data;
      })
      .catch(err => {
        if (err !== 'The user is not authenticated') {
          toast.error('Something went wrong. Please refresh the page.', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        throw err;
      });

  const getUsage = () =>
    Auth.currentAuthenticatedUser()
      .then(user => {
        const token = user.signInUserSession.idToken.jwtToken;
        return API.get('api230deb2c','/usage', {headers: { Authorization: token }});
      })
      .then(data => {
        setPlan(data.plan);
        setApiKey(data.apikey);
        return data;
      })
      .catch(err => {
        if (err !== 'The user is not authenticated') {
          toast.error('Something went wrong. Please refresh the page.', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        throw err;
      });

  const subscribe = (plan, cardToken) => {
    var processingToast = toast.info('Processing...', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return Auth.currentAuthenticatedUser()
      .then(user => {
        const token = user.signInUserSession.idToken.jwtToken;
        var body = {};
        if (plan) {
          body.plan = plan;
        }
        if (cardToken) {
          body.card_token = cardToken;
        }
        return API.post('api230deb2c','/subscribe', {headers: { Authorization: token }, body: body});
      })
      .then(data => {
        setPlan(data.plan);
        setApiKey(data.apikey);
        toast.dismiss(processingToast);
        return data;
      })
      .catch(err => {
        toast.dismiss(processingToast);
        if (err.message !== 'Request failed with status code 402' && err !== 'The user is not authenticated') {
          toast.error('Something went wrong. Please refresh the page.', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        throw err;
      });
    }

  const values = React.useMemo(() => ({ user, plan, apiKey, logout, getSubscription, getUsage, subscribe }), [user, plan, apiKey]);

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = React.useContext(UserContext);
  if(context === undefined) {
    throw new Error('`useUser` hook must be used within a `UserProvider` component');
  }
  return context;
};