import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Tracking from './tracking';

function Tutorial() {
  Tracking();
  return (
    <article className="TutorialArticle">
        <header>
            <h1>How To Use YH Finance API in 2022 [Tutorial]</h1>
        </header>
        <section>
            <div className="TutorialVideo">
                <iframe src="https://www.youtube.com/embed/2Gz8i7mSmf4?autoplay=1&mute=1" title="How to sign up" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </section>
        <section>
            <h2>What is YH Finance API?</h2>
            <p>The API serves real-time and historical data for crypto and stock markers. It provides extensive financial data for public companies, mutual funds, etf, bonds, crypto currencies, and national currencies, including option chains and market analysis. The free tier of YH Finance API gives 100 calls per day. Paid plan starts from $9.99/month.</p>
            <script type="application/ld+json">{`
                {
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": [
                        {
                            "@type": "Question",
                            "name": "What is YH Finance API?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "The API serves real-time and historical data for crypto and stock markers. It provides extensive financial data for public companies, mutual funds, etf, bonds, crypto currencies, and national currencies, including option chains and market analysis. The free tier of YH Finance API gives 100 calls per day. Paid plan starts from $9.99/month."
                            }
                        }
                    ]
                }
            `}</script>
        </section>
        <section>
            <h2>Why YH Finance API?</h2>
            <ul>
                <li><b>Impressive range of data.</b> On-top of the core standard data, the YH Finance API offers extras such as options and fundamentals data as well as market analysis. The API provides data for both Stock and Crypto markets as well as ETFs, mutual funds, and national currencies.</li>
                <li><b>Real Time and Low Latency.</b> Fast speed is a must for a Finance app. The YH Finance API delivers real time data with low latency.</li>
                <li><b>Simple.</b> The API structure is easy to understand with the provided documentation. The first result can be received within few minutes by writing only few lines of code. It’s also easy to set yourself up. The video above demonstrates how to sign up for the API key.</li>
            </ul>
            <script type="application/ld+json">{`
                {
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": [
                        {
                            "@type": "Question",
                            "name": "Why YH Finance API?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Impressive range of data. On-top of the core standard data, the YH Finance API offers extras such as options and fundamentals data as well as market analysis. The API provides data for both Stock and Crypto markets as well as ETFs, mutual funds, and national currencies."
                            }
                        },
                        {
                            "@type": "Question",
                            "name": "Why YH Finance API?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Real Time and Low Latency. Fast speed is a must for a Finance app. The YH Finance API delivers real time data with low latency."
                            }
                        },
                        {
                            "@type": "Question",
                            "name": "Why YH Finance API?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Simple. The API structure is easy to understand with the provided documentation. The first result can be received within few minutes by writing only few lines of code. It’s also easy to set yourself up. The video above demonstrates how to sign up for the API key."
                            }
                        }
                    ]
                }
            `}</script>
        </section>
        <section>
            <h2>API endpoints</h2>
            <p>The YH Finance API provides 11 endpoints, each of which covers a specific feature that you can use:
                <ol>
                    <li><b>/v6/finance/quote</b> - real time quote data for stocks, ETFs, mutuals funds, bonds, crypto and national currencies.</li>
                    <li><b>/v7/finance/options</b> - option chains data for a particular stock market company</li>
                    <li><b>/v8/finance/spark</b> - historical data for various intervals and ranges</li>
                    <li><b>/v11/finance/quoteSummary</b> - very detailed information for a particular ticker symbol</li>
                    <li><b>/v8/finance/chart</b> - chart data</li>
                    <li><b>/v6/finance/recommendationsbysymbol</b> - list of similar stocks</li>
                    <li><b>/ws/screeners/v1/finance/screener/predefined/saved</b> - list of most added stocks to the watchlist</li>
                    <li><b>/ws/insights/v1/finance/insights</b> - research insights</li>
                    <li><b>/v6/finance/autocomplete</b> - auto complete stock suggestions</li>
                    <li><b>/v6/finance/quote/marketSummary</b> - live market summary information at the request time</li>
                    <li><b>/v1/finance/trending</b> - trending stocks in a specific region</li>
                </ol>
            </p>
        </section>
        <section>
            <h2>How to use YH Finance API in Node.js (Javascript)?</h2>
            <SyntaxHighlighter language="javascript" style={a11yDark}>{`
var axios = require("axios").default;

var options = {
  method: 'GET',
  url: 'https://yfapi.net/v11/finance/quoteSummary/AAPL',
  params: {modules: 'defaultKeyStatistics,assetProfile'},
  headers: {
    'x-api-key': 'YOUR-PERSONAL-API-KEY'
  }
};

axios.request(options).then(function (response) {
	console.log(response.data);
}).catch(function (error) {
	console.error(error);
});
            `}</SyntaxHighlighter>
        </section>
        <section>
            <h2>How to use YH Finance API in Python?</h2>
            <SyntaxHighlighter language="python" style={a11yDark}>{`
import requests

url = "https://yfapi.net/v6/finance/quote"

querystring = {"symbols":"AAPL,BTC-USD,EURUSD=X"}

headers = {
    'x-api-key': "YOUR-PERSONAL-API-KEY"
    }

response = requests.request("GET", url, headers=headers, params=querystring)

print(response.text)
            `}</SyntaxHighlighter>
        </section>
        <section>
            <h2>How to use YH Finance API in PHP?</h2>
            <SyntaxHighlighter language="php" style={a11yDark}>{`
$curl = curl_init();

curl_setopt_array($curl, [
	CURLOPT_URL => "https://yfapi.net/v7/finance/options/AAPL",
	CURLOPT_RETURNTRANSFER => true,
	CURLOPT_FOLLOWLOCATION => true,
	CURLOPT_ENCODING => "",
	CURLOPT_MAXREDIRS => 10,
	CURLOPT_TIMEOUT => 30,
	CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
	CURLOPT_CUSTOMREQUEST => "GET",
	CURLOPT_HTTPHEADER => [
		"x-api-key: YOUR-PERSONAL-API-KEY"
	],
]);

$response = curl_exec($curl);
$err = curl_error($curl);

curl_close($curl);

if ($err) {
	echo "cURL Error #:" . $err;
} else {
	echo $response;
}
            `}</SyntaxHighlighter>
        </section>
        <section>
            <h2>How to use YH Finance API in Ruby?</h2>
            <SyntaxHighlighter language="ruby" style={a11yDark}>{`
require 'uri'
require 'net/http'
require 'openssl'

url = URI("https://yfapi.net/v8/finance/spark?symbols=AAPL")

http = Net::HTTP.new(url.host, url.port)
http.use_ssl = true
http.verify_mode = OpenSSL::SSL::VERIFY_NONE

request = Net::HTTP::Get.new(url)
request["x-api-key"] = 'YOUR-PERSONAL-API-KEY'

response = http.request(request)
puts response.read_body
            `}</SyntaxHighlighter>
        </section>
        <section>
            <h2>How to use YH Finance API in Java?</h2>
            <SyntaxHighlighter language="java" style={a11yDark}>{`
HttpRequest request = HttpRequest.newBuilder()
.uri(URI.create("https://yfapi.net/v8/finance/chart/AAPL"))
.header("x-api-key", "YOUR-PERSONAL-API-KEY")
.method("GET", HttpRequest.BodyPublishers.noBody())
.build();
HttpResponse<String> response = HttpClient.newHttpClient()
.send(request, HttpResponse.BodyHandlers.ofString());
System.out.println(response.body());
            `}</SyntaxHighlighter>
        </section>
    </article>
  );
}

export default Tutorial;