import React from 'react';
import './App.css';
import Amplify from 'aws-amplify'
import { Authenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from './user';

import Specification from "./Specification";
import Dashboard from "./Dashboard";
import Pricing from "./Pricing";
import Payment from "./Payment";
import Tutorial from "./Tutorial";
import Privacy from "./Privacy";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

function App() {
  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <ul className="header-links">
            <li>
              <Link to="/">Specification</Link>
            </li>
            <li>
              <Link to="/tutorial">Tutorial</Link>
            </li>
            <li>
              <Link to="/pricing">Pricing</Link>
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <LoginButtons />
          </ul>
        </header>
        <main className="App-main">
          <Switch>
            <Route exact path="/">
              <Specification />
            </Route>
            <Route exact path="/alpha">
              <Specification tab="1" />
            </Route>
            <Route path="/tutorial">
              <Tutorial/>
            </Route>
            <Route path="/pricing">
              <Pricing/>
            </Route>
            <Route path="/dashboard">
              <Authenticator loginMechanisms={['email']} variation="modal">
                {() => { return (<Dashboard/>)}}
              </Authenticator>
            </Route>
            <Route path="/payment">
              <Authenticator loginMechanisms={['email']} variation="modal">
                {() => { return (<Payment />)}}
              </Authenticator>
            </Route>
            <Route path="/privacy">
              <Privacy/>
            </Route>
            <Route>
              <Specification />
            </Route>
          </Switch>
          <ToastContainer />
        </main>
        <footer>
          <Footer />
        </footer>
      </Router>
    </div>
  );

  function LoginButtons() {
    const { user, logout } = useUser();
    const history = useHistory();
    return user ? (
      <li>
        <a href="#" onClick={() => {logout(); history.push("/pricing");}}>Sign out</a>
      </li>
    ) : (
      <li>
        <Link to="/dashboard">Sign in</Link>
      </li>
    );
  }
  
  function Footer() {
    const { plan } = useUser();
    return (!plan || plan === 'BASIC') ? '' : (
    //return (
      <div className="AppFooter">
        <span>
          Questions? Please email us at&nbsp;
        </span> 
        <a href="mailto:support@financeapi.net">support@financeapi.net</a>
        {/* <br/>
        <a href="/privacy">Privacy</a> */}
      </div>
    );
  }
}

export default App;
